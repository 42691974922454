@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,300i,400,400i,600,600i,700,700i|IBM+Plex+Serif:300,300i,400,400i,700,700i');
@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap4/scss/_functions.scss";
@import "../../bower_components/bootstrap4/scss/_variables.scss";
@import "../../bower_components/bootstrap4/scss/_mixins.scss";
@import "../../bower_components/bootstrap4/scss/_root.scss";
@import "../../bower_components/bootstrap4/scss/_reboot.scss";
@import "../../bower_components/bootstrap4/scss/_type.scss";
@import "../../bower_components/bootstrap4/scss/_images.scss";
@import "../../bower_components/bootstrap4/scss/_code.scss";
@import "../../bower_components/bootstrap4/scss/_grid.scss";
@import "../../bower_components/bootstrap4/scss/_tables.scss";
@import "../../bower_components/bootstrap4/scss/_forms.scss";
@import "../../bower_components/bootstrap4/scss/_buttons.scss";
@import "../../bower_components/bootstrap4/scss/_transitions.scss";
@import "../../bower_components/bootstrap4/scss/_dropdown.scss";
@import "../../bower_components/bootstrap4/scss/_button-group.scss";
@import "../../bower_components/bootstrap4/scss/_input-group.scss";
@import "../../bower_components/bootstrap4/scss/_custom-forms.scss";
@import "../../bower_components/bootstrap4/scss/_nav.scss";
@import "../../bower_components/bootstrap4/scss/_navbar.scss";
@import "../../bower_components/bootstrap4/scss/_card.scss";
@import "../../bower_components/bootstrap4/scss/_breadcrumb.scss";
@import "../../bower_components/bootstrap4/scss/_pagination.scss";
@import "../../bower_components/bootstrap4/scss/_badge.scss";
@import "../../bower_components/bootstrap4/scss/_jumbotron.scss";
@import "../../bower_components/bootstrap4/scss/_alert.scss";
@import "../../bower_components/bootstrap4/scss/_progress.scss";
@import "../../bower_components/bootstrap4/scss/_media.scss";
@import "../../bower_components/bootstrap4/scss/_list-group.scss";
@import "../../bower_components/bootstrap4/scss/_close.scss";
@import "../../bower_components/bootstrap4/scss/_modal.scss";
@import "../../bower_components/bootstrap4/scss/_tooltip.scss";
@import "../../bower_components/bootstrap4/scss/_popover.scss";
@import "../../bower_components/bootstrap4/scss/_carousel.scss";
@import "../../bower_components/bootstrap4/scss/_utilities.scss";
@import "../../bower_components/bootstrap4/scss/_print.scss";
// endbower

@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "fonts/fontawesome";
@import "fonts/fa-brands";
@import "fonts/fa-light";
@import "fonts/fa-regular";
@import "fonts/fa-solid";
@import "fonts/gotham";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/tinymce";