#hero {
	padding: 0 0 0 0;
	border-bottom: 1px solid #eee;
}

// body.home {
// 	#content {
// 		padding: 0 0 1rem 0;
// 	}
// }

.box {
	background: #fff;
	border-radius: 0 0 0 0;
}

#mast {
	h1 {
		font-weight: 700;
	}
}

.page-header {
	h1 {
		margin: 0 0 0 0;
		line-height: 1;
	}
}

.page-content {
	padding: 0 2rem;
}

body.home {
	#content {
		padding-bottom: 0;
	}
}

#card-affiliates {
	background: url(../images/bg-affiliates.jpg) no-repeat center center transparent;
	background-size: cover;
}

#card-data {
	background: url(../images/bg-data.jpg) no-repeat center center transparent;
	background-size: cover;
}

#card-team {
	background: url(../images/bg-team.jpg) no-repeat center center transparent;
	background-size: cover;
}

#card-connect {
	background: url(../images/bg-connect.jpg) no-repeat center center transparent;
	background-size: cover;
}

.no-gutter {
	padding: 0 0 0 0;
}

.chair {
	width: 100%;
	margin-bottom: 1.5rem;
}