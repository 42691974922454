#nav-secondary {
	> li {
		> a {
			display: block;
			padding: .25rem 1rem .25rem .75rem;
			border-left: .25rem solid $gray-light;
			margin-bottom: .25rem;
			text-transform: uppercase;
			color: $body-color;
			&:hover {
				color: #16181b;
				text-decoration: none;
				background-color: #f8f9fa;				
			}
		}
		&.current_page_item {
			a {
				color: $blue;
			}
		}
	}
}