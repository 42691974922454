body,
nav,
header,
section,
footer,
.container {
	position: relative;
}

.section {
	padding: 2rem 0;
	&.section-lg {
		padding: 8rem 0;
		min-height: 100vh;
	}
}

.center-vert {
	width: 100%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
}

.widget {
	margin-bottom: 2rem;
}

.card {
	margin-bottom: 2rem;
	&.h-100 {
		@include media-breakpoint-down(md) {
			height: auto !important;
		}
	}
	.card-body {
		padding: 2rem;
	}
}

.card-img-top {
	width: 100%;
	height: auto;
}

h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
	small {
		display: block;
	}
}

hr {
	margin: 2rem 0;
	border-width: .25rem;
	border-color: #f8f9fa;
}

blockquote {
	margin-bottom: 1rem;
	padding: 2rem;
	border: .25rem solid $gray-light;
	p {
		font-family: $font-family-serif;
		font-style: italic;
		font-weight: 300;
		@include media-breakpoint-up(md) {
			font-size: 1.5rem;
		}
	}
	cite {
		margin-bottom: 0;
		font-style: normal;
		@extend .blockquote-footer;
	}
}

.date {
	width: 4rem;
	span {
		display: block;
		text-transform: uppercase;
		font-weight: 700;
		padding: .25rem .625rem;
		text-align: center;
	}
	.month {
		background: $blue;
		color: #fff;
		box-shadow: .125rem .125rem 0 #ddd;
		border-radius: $border-radius-sm $border-radius-sm 0 0;
	}
	.day {
		@extend .bg-light;
		box-shadow: .125rem .125rem 0 #eee;
		font-size: 2rem;
		border-radius: 0 0 $border-radius-sm $border-radius-sm;
		&.day-light {
			@extend .bg-white;
		}
	}
}

.updated {
	display: block;
	text-transform: uppercase;
	margin-bottom: .5rem;
}

.pb-2rem {
	@include media-breakpoint-up(lg) {
		padding-bottom: 2rem;
	}
}

.gallery {
	@extend .row;
	.gallery-item {
		@extend .col-6;
		@extend .col-sm-4;
		margin-bottom: 2rem;
		border: 0px none;
		border-radius: 0;
	    text-align: center;
		img {
			@extend .img-fluid;
		}
		figcaption {
			padding: .5rem .1rem;
			text-align: center;
		}
	}
}

figure {
	&.wp-block-image {
		img {
			@extend .img-fluid;
			@extend .rounded;
		}
		figcaption {
			font-style: italic;
			@extend .text-muted;
		}
	}
}

.deck {
   display: flex;
   flex-wrap: wrap;
}

.deck > div[class*='col-'] {
  display: flex;
}

.breadcrumb {
	padding: 0 0;
	background: transparent;
	border-radius: 0 0 0 0;
	margin: -1rem 0 2rem 0;
	font-size: .75rem;
	text-transform: uppercase;
	@extend .text-muted;
	@include media-breakpoint-down(sm) {
		display: none;
	}
	a {
		@extend .text-muted;
	}
}