.dropdown-menu {
	border: 0px none;
	padding: 1.25rem 1.25rem 1rem 1.25rem;
	border-top: .25rem solid #454554;
	border-radius: 0 0 $border-radius $border-radius;
	margin-top: 0 !important;
	transform: translateY(.375rem);
	@include media-breakpoint-up(md) {
		box-shadow: 1px 1px 3px rgba(0,0,0,.1);
		min-width: 100%;
	}
	.dropdown-item {
		padding: .25rem 1rem .25rem .75rem;
		border-left: .25rem solid $gray-light;
		margin-bottom: .25rem;
		text-transform: uppercase;
	}
	.active,
	.open,
	.show {
		.dropdown-item {
			color: $blue;
		}
	}
}

.navbar-expand-md > .container, 
.navbar-expand-md > .container-fluid,
.navbar-expand-lg > .container, 
.navbar-expand-lg > .container-fluid {
	@media (min-width: 576px) and (max-width: 991.98px) {
		padding-right: 15px;
		padding-left: 15px;
	}
}

.navbar-brand {
	@extend .text-hide;
	background: url(../images/sc-innovates.png) center center;
	background-size: 220px 30px;
	width: 220px;
	height: 30px;
}

.home {
	.nav-home {
		@extend .d-none;
	}
}

#navbar-branding {
	padding: 2rem 1rem;
}

#navbar-menu {
	border-radius: 0 0 0 0;
	padding: .375rem 1rem;
}

	.navbar {
	&.bg-light {
		background-color: #f8f9fa !important;
		box-shadow: 2px 2px 4px hsla(0%, 0%, 0%, .1)
	}
	.navbar-nav {
		margin: 0 -1rem;
	}
	.nav-link {
		text-transform: uppercase;
		padding: .5rem 1rem !important;
	}
	.navbar-nav {
		.nav-item {
			&.active,
			&.open,
			&.show,
			&.current_page_parent,
			&.current-page-ancestor {
				> .nav-link {
					color: white;
				}
			}
		}
	}
	.navbar-toggler {
		text-transform: uppercase;
		border: 0px none;
		border-radius: 0 0 0 0;
		padding: .6125rem 1rem;
		margin: 0 -1rem;
	}
}