// Fonts
$fa-font-path: "../fonts";
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-family-serif: 'Merriweather', serif;
$headings-font-weight: 700;
$enable-responsive-font-sizes: true;
$breadcrumb-divider: quote(">");

// Colors
$body-bg: #343a40;
$white: #fff;
$gray: hsla(240, 10%, 30%, 1);
$blue: hsla(178, 93%, 39%, 1);
$red: hsla(355, 100%, 23%, 1);
$gray-light: hsla(252%, 19%, 95%, 1);
$gray-medium: hsla(207%, 16%, 75%, 1);
$gray-dark: hsla(200%, 3%, 22%, 1);
$body-color: $gray;
$navbar-light-color: $gray-dark;
$navbar-light-hover-color: #000000;
$navbar-light-active-color: $white;
$navbar-dark-color: rgba($white, .8);
$navbar-dark-hover-color: rgba($white, .9);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, .5);


$spacer: 1rem;
$spacers: ();
$spacers: map-merge((
  0: 0,
  1: ($spacer * .5),
  2: $spacer,
  3: ($spacer * 1.5),
  4: ($spacer * 2),
  5: ($spacer * 2.5)
), $spacers);


// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    3;
